<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    :persistent="load"
  >
    <v-card
      :disabled="load"
    >
      <v-card-title>
        Nuevo objetivo
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="6" md="3" class="py-0">
              <FechaPickerMes
                :fecha.sync="periodo"
                :label="true"
                :clearable="false"
              />
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <v-autocomplete
                v-model="familia"
                label="Familia de objetivos"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="familias"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="get_tipos(); get_locales()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="tipo"
                label="Tipo de objetivo"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="tipos"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="$store.state.sucursales"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="get_locales()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="local"
                label="Punto de venta"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="locales"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-card>
                <v-card-text class="pb-0 pt-8">
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" sm="4" md="3" class="py-0">
                      <!-- <v-text-field
                        v-model="objetivo"
                        type="number"
                        label="Objetivo pto vta"
                        prefix="$"
                        tabindex="1"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        dense
                        @blur="calcular_obj_x_vend()"
                      ></v-text-field> -->
                      <TextFieldMoneyVue
                        v-model="objetivo"
                        label="Objetivo pto vta"
                        @blur="calcular_obj_x_vend()"
                        v-bind:properties="{
                          rules: [rules.required],
                          'prefix': '$',
                          'validate-on-blur': true,
                          'outlined': true,
                          'dense': true,
                        }"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" md="3" class="py-0">
                      <v-text-field
                        v-model="vendedores"
                        type="number"
                        label="Cant vendedores"
                        tabindex="1"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        dense
                        @blur="calcular_obj_x_vend()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3" class="py-0">
                      <!-- <v-text-field
                        v-model="obj_x_vend"
                        type="number"
                        label="Objetivo x vend"
                        prefix="$"
                        tabindex="1"
                        outlined
                        readonly
                        filled
                        dense
                      ></v-text-field> -->
                      <TextFieldMoneyVue
                        v-model="obj_x_vend"
                        label="Objetivo x vend"
                        v-bind:properties="{
                          'prefix': '$',
                          'filled': true,
                          'readonly': true,
                          'validate-on-blur': true,
                          'outlined': true,
                          'dense': true,
                        }"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format_money, get_periodo } from '../../util/utils'
import FechaPickerMes from '../util/FechaPickerMes'
import BtnConfirmar from '../util/BtnConfirmar'
import { mapState } from 'vuex'
import TextFieldMoneyVue from '../util/TextFieldMoney.vue'

export default {
  data () {
    return {
      format_money: format_money,
      load: false,
      periodo: get_periodo(),
      familia: 3,
      tipo: null,
      sucursal: null,
      local: null,
      objetivo: null,
      vendedores: null,
      obj_x_vend: null,
      tipos: [],
      locales: [],
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  async created () {
    this.familia = 3
    this.periodo = get_periodo()
    this.get_tipos()
    this.$store.state.loading = true
    await this.$store.dispatch('vendedores/get_locales_user')
    this.$store.state.loading = false
    if (this.$store.state.sucursales.length === 1) {
      this.sucursal = this.$store.state.sucursales[0].id
      this.get_locales()
    }
  },
  props: {
    value: Boolean,
    familias: Array, // familias de objetivos
  },
  components: {
    FechaPickerMes,
    BtnConfirmar,
    TextFieldMoneyVue
  },
  computed: {
    ...mapState('vendedores', ['locales_user']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        await this.$store.dispatch('objetivos/nuevo_obj_ptovta', {
          periodo: this.periodo,
          tipo: this.tipo,
          ptovta: this.local,
          objetivo: this.objetivo,
          vendedores: this.vendedores,
        })
          .then((res) => {
            this.dialog = false
            this.$swal.fire({
              icon: 'success',
              title: res.message
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.load = false
      }
    },
    calcular_obj_x_vend () {
      if (this.objetivo && this.vendedores) {
        this.obj_x_vend = parseFloat(this.objetivo / this.vendedores).toFixed(2)
      }else{
        this.obj_x_vend = null;
      }
    },
    async get_tipos () {
      this.$store.state.loading = true
      this.tipos = []
      this.tipo = null
      await this.$store.dispatch('objetivos/get_tipos', this.familia)
        .then(res => {
          this.tipos = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    get_locales () {
      const familia = this.familias.find(fam => fam.id == this.familia)
      let categoria = null
      if (familia) categoria = familia.categoria
      this.locales = this.locales_user.filter(local => local.sucursal === this.sucursal && local.empresa === 2 && local.categoria == categoria)
      this.local = null
    },
  }
}
</script>