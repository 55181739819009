<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date_format"
        :label="label ? 'Periodo' : ''"
        :clearable="clearable"
        :disabled="disabled"
        hide-details
        outlined
        readonly
        filled
        dense
      >
        <template v-slot:append-outer>
          <v-icon
            v-bind="attrs"
            v-on="on"
            tabindex="1"
            :color="disabled ? '' : 'info'"
            :title="disabled ? '' : 'Seleccionar periodo'"
            :disabled="disabled"
          >
            fas fa-calendar-day
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      type="month"
      color="primary"
      show-current
      scrollable
      no-title
    ></v-date-picker>
  </v-menu>
</template>

<script>
/*
  Devuelve la fecha en formato 01/MM/YYYY

  ANTES DE USAR REVISAR EL COMPONENTE PeriodoPicker, PUEDE RESULTAR MÁS UTIL!

 */
export default {
  data () {
    return {
      menu: false,
      date: null,
    }
  },
  props: {
    fecha: String,
    label: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    date_format: {
      get () {
        return this.fecha
      },
      set (value) {
        this.$emit('update:fecha', value)
      }
    },
  },
  watch: {
    date () {
      this.date_format = this.format_date(this.date)
      this.$emit('change')
    }
  },
  methods: {
    format_date (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      return `01/${month}/${year}`
    },
  }
}
</script>